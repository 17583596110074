<!-- =========================================================================================
	File Name: Error404.vue
	Description: 404 Page
	----------------------------------------------------------------------------------------
	Item Name: Vuesax Admin - VueJS Dashboard Admin Template
	Version: 1.1
	Author: Pixinvent
	Author URL: hhttp://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
	<div class="h-screen flex w-full bg-img">
		<div class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-3/5 mx-auto text-center">
			<img src="../../assets/images/pages/404.png" alt="graphic-404" class="mx-auto mb-4 responsive">
			<h1 class="mb-3 text-5xl">404 - Page Not Found!</h1>
			<p class="mb-3">Oops, page not found.</p>
			<vs-button to="/">Back</vs-button>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
            value1: ''
		}
	}
}
</script>